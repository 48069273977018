import React from 'react'
import "./index.css"

export default function HomePage() {
    const padding = "p-1 basis-1/2"
    const borderSetting = "border-solid border-2 border-black rounded-md"
    const companyText = "font-semibold lg:mt-0 mt-2"
    return (
        <div class="flex-grow">
            <div class="flex flex-col-reverse lg:flex-row flex-wrap font-sans m-4 text-center">
                <div class={padding}>
                    <div class={borderSetting}>
                        <h1 class="text-lg font-bold underline underline-offset-2">Work Experience</h1>
                        <div class={" flex flex-col lg:flex-row flex-wrap"}>
                            <div class="basis-1/2">
                                <p class={companyText}>Aveva (Feb 2022 - Current)</p>
                                <p>Deliver Features and Fix Issues with the following technology:</p>
                                <ul>
                                    <li>React.js</li>
                                    <li>Serverless</li>
                                    <li>AWS (S3, Lambda and State Machine etc)</li>
                                    <li>C++</li>
                                    <li>C#</li>
                                </ul>
                            </div>
                            <div class="basis-1/2">
                                <p class={companyText}>Ergo Creative (June 2021 - Feb 2022)</p>
                                <p>Deliver Websites with:</p>
                                <ul>
                                    <li>React.js</li>
                                    <li>Express.js</li>
                                    <li>Vue.js</li>
                                    <li>MySQL</li>
                                    <li>PHP/Laravel</li>

                                </ul></div>
                            <div class="basis-1/2">
                                <p class={companyText}>KVB PRIME (Nov 2019 - June 2021)</p>
                                <p>Tasked With:</p>
                                <ul>
                                    <li>Managing MT4 system</li>
                                    <li>Organise Project</li>
                                    <li>Arranging Marketing Event</li>
                                    <li>B2B Communication</li>

                                </ul></div>
                            <div class="basis-1/2">
                                <p class={companyText}>Huawei (June 2019 - Nov 2019)</p>
                                <p>Tasked With:</p>
                                <ul>
                                    <li>Organise Project</li>

                                </ul></div>

                        </div></div>
                </div>

                <div class={padding}>
                    <div>
                        <h1 class="text-lg font-bold underline underline-offset-2">About Me</h1>
                        <div class={"flex flex-col flex-wrap"}>
                            <img class="m-auto animate-fadeIn" width="150" height="150" src={require("../../static/profile.jpg")} alt="I am shy"></img>
                            <p><b>Name:</b> Andrew Wong</p>
                            <p><b>Location:</b> Manchester/Salford</p>
                            <p><b>Linkedin:</b> <a href="https://www.linkedin.com/in/andrew-chung-man-wong-54129b132/"><u>here</u></a></p>
                            <p><b>Github:</b><a href="https://github.com/andrewroar">https://github.com/andrewroar</a></p>
                        </div>
                    </div>
                </div>


            </div>
            <div class={borderSetting + " m-4 text-center p-1"}>
                <h1 class="text-lg font-bold underline underline-offset-2">Education</h1>
                <p class={companyText}>University of Manchester Coding Bootcamp (2020)</p>
                <p><b>Learn About:</b> React.js, HTML, CSS, MySQL, MongoDB, Express.js, jQuery</p>
                <br></br>
                <p class={companyText}>MA. International Political Economy, University of York (2017-2018)</p>
                <p><b>Study About:</b> Chinese Internation Relations and History, Political Economic Subjects and Critical Political Economy</p>
                <br></br>
                <p class={companyText}>MA. Politics Economics and Philosophy: Economics and Development, University of York (2017-2018)</p>
                <p><b>Study About:</b> Econnometric, Political Theories and Various Economic model</p>
                <br></br>
                <p class={companyText}>BA. Politics Economics and Philosophy, University of York (2013-2016)</p>
                <p><b>Study About:</b> Honestly, forgot most of what I did but I'm glad you care</p>
            </div>

        </div >
    )
}
