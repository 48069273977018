import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { Disclosure, Menu } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const pathRef = {
    base: "/",
    ai: "/cat-dog",
    project: "/project"
}


export default function Navigation() {
    useEffect(() => {
        const queryString = window.location.pathname;
        const newNavibar = navigation.map(item => queryString == item.href ? { ...item, current: true } : { ...item, current: false })
        setNavigation(newNavibar)
    }, [])

    const [navigation, setNavigation] = useState([
        { name: 'Home', href: pathRef.base, current: false },
        { name: 'Projects', href: pathRef.project, current: false },
        { name: 'My AI Model', href: pathRef.ai, current: false }
    ])

    const setNavbarState = (currentItem) => {
        const newNavibar = navigation.map(item => currentItem == item ? { ...item, current: true } : { ...item, current: false })
        setNavigation(newNavibar)
    }

    const ToggleBtn = () => {
        return (<label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" class="sr-only peer" />
            <div onClick={() => { console.log("Hello") }} class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ml-3 text-sm font-medium text-white dark:text-gray-300">Dark Mode</span>
        </label>)
    }
    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src="https://cdn-icons-png.flaticon.com/512/4600/4600417.png"
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src="https://cdn-icons-png.flaticon.com/512/4600/4600417.png"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (

                                            <Link to={item.href} onClick={() => { setNavbarState(item) }} className={classNames(
                                                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'px-3 py-2 rounded-md text-sm font-medium'
                                            )}
                                                aria-current={item.current ? 'page' : undefined}>{item.name}</Link>
                                        ))}
                                    </div>
                                </div>
                            </div><ToggleBtn />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="h-8 w-8 rounded-full"
                                                src={require("../../static/profile.jpg")}
                                                alt=""
                                            />
                                        </Menu.Button>
                                    </div>

                                </Menu>
                            </div>

                        </div>

                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Link to={item.href}>

                                    <Disclosure.Button
                                        onClick={() => { setNavbarState(item) }}
                                        key={item.name}
                                        as="a"
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block px-3 py-2 rounded-md text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button></Link>
                            ))}



                            <ToggleBtn />
                        </div>
                    </Disclosure.Panel>

                </>
            )}
        </Disclosure>
    )
}
