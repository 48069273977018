import React from 'react'
import { Link } from "react-router-dom"

export default function NotFound() {
    return (
        <div class="text-center h-screen"><h1 class="text-5xl mt-6">Oooooppppsss.....This Page does not exist</h1>
            <Link to={"/"}>            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 roundedv my-4">
                Home
            </button></Link>
        </div>
    )
}
