
import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function CatdogModel() {

    const [message, setMessage] = useState("")
    const [url, setURL] = useState("")
    const [image, setImage] = useState("")
    const [loading, setLoading] = useState(false)
    const [enableImage, setEnableImage] = useState(false)
    const handleChangeURL = (event) => {
        setURL(String(event.target.value))
    }

    const handleImageOnLoad = (image) => {
        setEnableImage(false)
    }
    const submitURL = async () => {
        setMessage("")
        setLoading(true)
        setImage(url)

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Methods", "POST")
        myHeaders.append("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With")

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "url": url
            })
        };

        setTimeout(async () => {
            try {
                const response = await fetch('https://andrewwongprofileapi.com/api/makePrediction', requestOptions);
                const identify = await response.json()
                if (identify.errorType) {
                    throw identify
                }

                setMessage(`This is a ${identify.result}`)
                setEnableImage(true)
                setLoading(false)
            } catch (error) {
                console.error(error);


                setMessage("there is an error, please make sure your url is correct")
                setLoading(false)

            }
        }, 3000)




    }
    return (
        <div class="flex flex-col justify-center items-center pt-4">
            {enableImage && <img className="w-40 h-40" src={image} onError={handleImageOnLoad} alt="" />}

            <h3 class="m-2 font-bold">Add an URL of a picture of a dog or a cat and I will guess which one it is</h3>
            <div class="flex items-center">
                <input type="text" onChange={handleChangeURL} class="px-3 py-2 border border-gray-300 rounded-md ml-2 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                {loading ? <CircularProgress /> : <button type="submit" onClick={async () => { await submitURL() }} class="px-4 py-2 bg-blue-500 text-white rounded-md ml-2 hover:bg-blue-600">Submit</button>}

            </div>

            <h3>{message}</h3>

        </div>

    )
}
