import React from 'react'

export default function Projects() {
    return (
        <div class="flex flex-col justify-center">
            <div class="flex flex-col lg:flex-row font-sans m-5 text-center border-solid border-2 border-black rounded-md">
                <div class="basis-2/3 mx-2 p-3">
                    <p><b>Project Name:</b> Bible Quote (Discontinued)</p>
                    <p><b>Link:</b> <a href="https://play.google.com/store/apps/details?id=com.andrewroar.biblequote">https://play.google.com/store/apps/details?id=com.andrewroar.biblequote</a></p>
                    <p><b>Description:</b> A React Native App which allows user to generate a quote from the bible</p>
                </div>
                <div class="basis-1/3 mx-2 p-3"><img class="lg:mx-0 mx-auto" src={require("../../static/300X300.png")}></img></div>
            </div>
            <div class="flex flex-col-reverse lg:flex-row font-sans m-5 text-center border-solid border-2 border-black rounded-md">
                <div class="basis-1/3 mx-2 p-3"><img class="lg:mr-0 mx-auto" src={require("../../static/icon.png")}></img></div>
                <div class="basis-2/3 mx-2 p-3">
                    <p><b>Project Name:</b> Shiba Fitness</p>
                    <p><b>Link:</b> TBH</p>
                    <p><b>Description:</b> A React Native App which allows user to keep track of his/her progress</p>
                </div>

            </div>
        </div>
    )
}
