import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import HomePage from "./components/home"
import NotFound from "./components/notFound"
import CatdogModel from "./components/catdogModel"
import NavigationBar from './components/navigationBar';
import Projects from './components/projects';

function App() {
  return (<Router>
    <div className="App flex flex-col">
      <NavigationBar />


      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/project" element={<Projects />} />
        <Route exact path="/cat-dog" element={<CatdogModel />} />
        <Route path="*" element={<NotFound />} />
      </Routes>



    </div >
  </Router >
  );
}

export default App;
